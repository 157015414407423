<template>
  <header class="z-40 w-full border-b border-primary-1 bg-base-2 shadow">
    <div v-if="menuData" class="relative flex flex-row items-center gap-2">
      <div class="ml-4 hidden h-12 p-0.5 sm:block md:h-14">
        <a
          href="https://www.assecods.pl/oferta/oswiata"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <img
            v-if="contrast === 'contrast-3'"
            src="~common-assets/logotypes/assecods_black.svg"
            class="my-3 h-6 md:h-8"
            alt="Logo AssecoDS"
          />
          <img
            v-else-if="contrast === 'contrast-1' || contrast === 'contrast-2'"
            src="~common-assets/logotypes/assecods_white.svg"
            class="my-3 h-6 md:h-8"
            alt="Logo AssecoDS"
          />
          <img
            v-else
            src="~common-assets/logotypes/assecods.svg"
            class="my-3 h-6 md:h-8"
            alt="Logo AssecoDS"
          />
        </a>
      </div>
      <div class="ml-4 hidden lg:inline">
        <h1 class="my-0">
          <span class="text-base-1">
            {{ menuData?.options.moduleMainName }}
          </span>
        </h1>
      </div>
      <div class="hidden sm:block shrink-0 grow"></div>
      <div class="ml-4 h-12 p-0.5 md:h-14">
        <LoadingImage
          class="h-full w-full contrast:hidden contrast:text-base-1"
          :src="
            menuData && menuData.options.moduleLogoPath
              ? menuData.options.moduleLogoPath
              : ''
          "
          :alt="
            menuData && menuData.localization.altImageText
              ? menuData.localization.altImageText
              : ''
          "
        />
        <LoadingImage
          class="h-full w-full hidden contrast:block contrast:text-base-1"
          :src="
            menuData && menuData.options.moduleContrastLogoPath
              ? menuData.options.moduleContrastLogoPath
              : ''
          "
          :alt="
            menuData && menuData.localization.altImageText
              ? menuData.localization.altImageText
              : ''
          "
        />
      </div>
      <span
        v-if="menuData?.options.cityName"
        class="text-primary-1 font-medium text-lg"
      >
        {{ menuData?.options.cityName }}
      </span>
      <div class="sm:hidden shrink-0 grow"></div>
      <div class="flex flex-row items-stretch self-stretch">
        <div
          class="self-stretch w-0 border-l border-neutral-7 my-1 mx-1 contrast:border-base-1"
        ></div>
        <HeaderThemeButtons class="self-center" :menu-data="menuData" />
        <div
          class="self-stretch w-0 border-l border-neutral-7 my-1 mx-1 contrast:border-base-1"
        ></div>
      </div>
    </div>
  </header>
</template>
<script setup lang="ts">
const { data: menuData } = await useMenuData();
const { contrast } = useTheme();
</script>
